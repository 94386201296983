import Header from "../components/Header";
import BG from "../assets/image/bg-gillette.png";
import INPUT from "../assets/image/input.png";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { receiptServices } from "../services/apiServices";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import BUTTON_JOIN from "../assets/image/button-join.png";
import BUTTON_DIS from "../assets/image/btn-disable.png";
import BUTTON_TC from "../assets/image/button-tc.png";
import { useNavigate } from "react-router-dom";

export default function CheckPhoneShopee() {
  const appCode = window.location.pathname.split("/")[1];

  const background = {
    background: "url(" + BG + ")",
    minHeight: "100vh",
  };
  useEffect(() => {
    // receiptServices
    //   .submitGilletteFlexivibe()
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const navigation = useNavigate();
  const onSubmit = (data) => {
    console.log(data);
    localStorage.setItem("INFOR", JSON.stringify(data));
    // const infor = {
    //   name: data.name,
    //   phone: data.phone,
    //   code: data.code,
    // };
    navigation("/shopee/upload");
  };
  const [checkAgree1, setCheckAgree1] = useState(false);
  const [checkAgree2, setCheckAgree2] = useState(false);
  const handleAgree = (e) => {
    if (e === "ag1") {
      setCheckAgree1(!checkAgree1);
    } else if (e === "ag2") {
      setCheckAgree2(!checkAgree2);
    }
  };
  const handleListReceiveCode = () => {
    navigation(`/${appCode}/receive_code`);
  };
  return (
    <div>
      <Header />

      <div className="relative top-0 z-0">
        {/* <div style={background} /> */}
        <div className="absolute top-0 z-10">
          <div className="font-regular-mon mt-3 text-white text-[15px] text-justify px-5">
            Vui lòng cung cấp thêm thông tin chính xác để nhận quà.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="flex flex-col justify-center items-center relative z-10 px-5"
              // style={style}
            >
              <div className="mt-2 relative">
                <img src={INPUT} className="w-full relative" />
                <input
                  className="input-hidden text-[16px] font-regular-mon absolute top-0 w-full h-full left-0 pl-5"
                  placeholder="Vui lòng nhập tên"
                  {...register("name", {
                    required: "Không được để trống",
                  })}
                />
              </div>
              <div className="font-bold-mon z-0 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-2 z-0 rounded-bl-xl rounded-br-xl
                            border-transparent border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="mt-2 relative">
                <img src={INPUT} className="w-full relative" />
                <input
                  className="input-hidden text-[16px] font-regular-mon absolute top-0 w-full h-full left-0 pl-5"
                  placeholder="Vui lòng nhập số điện thoại"
                  type="tel"
                  {...register("phone", {
                    required: "Không được để trống",
                    pattern: {
                      value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                      message: "Vui lòng nhập đúng định dạng số điện thoại",
                    },
                  })}
                />
              </div>
              <div className="font-bold-mon z-0 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-1 -mt-2 z-0 rounded-bl-xl rounded-br-xl
                            border-transparent border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
              <div className="mt-4 relative">
                <img src={INPUT} className="w-full relative" />
                <input
                  className="input-hidden text-[16px] font-regular-mon absolute top-0 w-full h-full left-0 pl-5"
                  placeholder="Mã đơn hàng"
                  {...register("code", {
                    required: "Không được để trống",
                  })}
                />
              </div>
              <div className="font-bold-mon z-0 text-[red] text-[13px] w-full  text-center">
                <ErrorMessage
                  errors={errors}
                  name="code"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-2 z-0 rounded-bl-xl rounded-br-xl
                             border-transparent border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="px-5 text-white">
              <div className="mt-2 flex font-light-mon">
                <div>
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    checked={checkAgree1}
                    value={checkAgree1}
                    onClick={(e) => handleAgree("ag1")}
                    className="checkbox-confirm-register w-3 h-3 text-blue-600"
                  />
                </div>
                <label
                  htmlFor="check"
                  className="text-[#333333] mr-[10px] text-[13px]"
                >
                  {" "}
                </label>
                <label
                  htmlFor="check"
                  className={`mr-[10px] text-justify font-regular-mon not-italic text-[13px]`}
                >
                  Tôi đồng ý với các Điều khoản và Điều kiện khi tham gia chương
                  trình. 
                </label>
              </div>

              <div className="mt-1 flex font-light-mon">
                <div>
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    checked={checkAgree2}
                    value={checkAgree2}
                    onClick={(e) => handleAgree("ag2")}
                    className="checkbox-confirm-register w-3 h-3 text-blue-600"
                  />
                </div>
                <label
                  htmlFor="check"
                  className="text-[#333333] mr-[10px] text-[13px]"
                >
                  {" "}
                </label>
                <label
                  htmlFor="check"
                  className={`mr-[10px] text-justify font-regular-mon text-[13px]`}
                >
                  Tôi đồng ý với các Điều khoản và Điều kiện khi tham gia chương
                  trình. Khi đánh dấu vào ô này, Tôi đồng ý cho công ty P&G và
                  công ty cung cấp dịch vụ được P&G ủy quyền là công ty TNHH
                  Truyền Thông MVC được sử dụng, lưu trữ các thông tin cá nhân
                  do tôi cung cấp, trong hoặc ngoài lãnh thổ Việt Nam, vào mục
                  đích trao giải thưởng, báo cáo, quản lý chương trình khuyến
                  mại. P&G và công ty dịch vụ được ủy quyền sẽ lưu trữ các thông
                  tin cá nhân trong thời hạn phù hợp với qui định của pháp luật.
                  Để được tiếp cận, cập nhật, điều chỉnh thông tin cá nhân, Tôi
                  sẽ liên hệ P&G và công ty được ủy quyền là công ty TNHH Truyền
                  Thông MVC ( http://mvc.com.vn) theo địa chỉ: 48 Hoa Mai,
                  Phường 2, Quận Phú Nhuận, Tphcm. Điện thoại: (028)35170278.
                  Chi tiết chính sách của P&G về quản lý thông tin riêng tư tại:{" "}
                  <a
                    href="http://www.pg.com/privacy/english/privacy_notice.shtml
"
                    target="_blank"
                  >
                    http://www.pg.com/privacy/ english/privacy_notice.shtml
                  </a>
                </label>
              </div>
            </div>
            <div className="relative z-50 top-5 px-10">
              {checkAgree1 && checkAgree2 ? (
                <button type="submit" className="w-full relative z-50 flex">
                  <img src={BUTTON_JOIN} className="w-full" />
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-white font-bold text-[18px] w-full uppercase">
                    <div>TIẾP TỤC</div>
                  </div>
                </button>
              ) : (
                <div className="w-full relative flex">
                  <img src={BUTTON_DIS} className="w-full" />
                  <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-white font-bold text-[18px] w-full uppercase">
                    <div>TIẾP TỤC</div>
                  </div>
                </div>
              )}

              <div
                className="w-full mt-2 relative"
                onClick={handleListReceiveCode}
              >
                <img src={BUTTON_TC} className="w-full" />
                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[#003DA5] font-bold text-[16px] leading-5 w-full ">
                  Danh sách khách hàng
                  <br /> tham gia
                </div>
              </div>
            </div>
          </form>
        </div>
        <img src={BG} className="relative z-0" />
        <Footer />
      </div>
    </div>
  );
}
