import Header from "../components/Header";
import BG from "../assets/image/bg-upload.png";
import INPUT from "../assets/image/input.png";
import Footer from "../components/Footer";
import { Fragment, useEffect, useState } from "react";
import { receiptServices } from "../services/apiServices";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import BUTTON_JOIN from "../assets/image/button-join.png";
import UPLOAD from "../assets/image/upload.png";
import CLOSE from "../assets/image/close.png";
import CONTENT from "../assets/image/content-upload.png";
import { useNavigate } from "react-router-dom";
import ICON_PHONE from "../assets/image/phone.png";
import BTN_NEXT from "../assets/image/btn-next.png";
import ICON_CAM from "../assets/image/icon-cam.png";
import BACK from "../assets/image/back.png";
import Compressor from "compressorjs";
import { format } from "date-fns";
import uuid from "react-uuid";
import SuccessShopee from "./SuccessShopee";

export default function UploadImageShopee() {
  const appCode = window.location.pathname.split("/")[1];

  const background = {
    background: "url(" + BG + ")",
    minHeight: "100vh",
  };
  useEffect(() => {}, []);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const navigation = useNavigate();
  const onSubmit = (data) => {
    console.log(data);
    localStorage.setItem("INFOR", JSON.stringify(data));
    // const infor = {
    //   name: data.name,
    //   phone: data.phone,
    //   code: data.code,
    // };
    navigation("/appcode/upload");
  };
  const [checkAgree1, setCheckAgree1] = useState(false);
  const [checkAgree2, setCheckAgree2] = useState(false);
  const [imageFile, setimageFile] = useState(undefined);
  const [isUpload, setIsUpload] = useState(false);

  const handleAgree = (e) => {
    if (e === "ag1") {
      setCheckAgree1(!checkAgree1);
    } else if (e === "ag2") {
      setCheckAgree2(!checkAgree2);
    }
  };
  const handleChangeImage = (event) => {
    const fileUploaded = event.target.files[0]; // Get the first selected file
    // setSelectedImage(file);
    console.log(fileUploaded);
    const fileUploadedSize = fileUploaded.size / 1024 / 1024;
    if (fileUploadedSize > 20) {
      new Compressor(fileUploaded, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          pushImageToGCS(res);
        },
      });
    } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
      new Compressor(fileUploaded, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          pushImageToGCS(res);
        },
      });
    } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
      new Compressor(fileUploaded, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          pushImageToGCS(res);
        },
      });
    } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
      new Compressor(fileUploaded, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          pushImageToGCS(res);
        },
      });
    } else {
      pushImageToGCS(fileUploaded);
    }
    console.log(fileUploaded);
  };
  const pushImageToGCS = (imageFile) => {
    console.log("hello");
    let formDataGCS = new FormData();
    console.log("minh");
    formDataGCS.append("file", imageFile);
    const fileName =
      uuid() +
      "_" +
      format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
      "_" +
      imageFile.name;
    formDataGCS.append("fileName", fileName);
    // formDataGCS.append("ocrBase", ocrEndpoint);

    setIsUpload(true);

    receiptServices
      .uploadImageToOCR(formDataGCS)
      .then((res) => {
        console.log(res);
        setimageFile(res?.data?.public_url);
        localStorage.setItem("GCS_RESULT", JSON.stringify(res.data));
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        setIsUpload(false);
      });
  };
  const [statusOpenNoti, setStatusOpenNoti] = useState(false);
  const [dataSuccess, setDataSuccess] = useState();
  const [statusShowContent, setStatusShowContent] = useState(false);
  const handleConfirm = () => {
    const dataInfor = JSON.parse(localStorage.getItem("INFOR"));
    const image = {
      image_url: imageFile,
      campaign_name: appCode,
    };
    const data = Object.assign({}, dataInfor, image);
    console.log(data);
    receiptServices
      .submitGilletteShopee(data)
      .then((res) => {
        console.log(res);
        if (res?.meta?.status_code === 200) {
          setStatusOpenNoti(true);
          setDataSuccess(res?.meta?.message);
        }
      })
      .catch((err) => {
        setStatusShowContent(true);
        setStatusOpenNoti(true);
        setDataSuccess(err?.meta?.message);
        console.log(err);
      });
  };
  const handleClose = () => {
    setimageFile(undefined);
  };
  return (
    <Fragment>
      {statusOpenNoti ? (
        <SuccessShopee data={dataSuccess} statusContent={statusShowContent} />
      ) : (
        <div className="bg-container">
          <Header />
          <div className="relative top-0 z-0 w-full">
            {/* <div style={background} /> */}
            <div className="absolute top-0 z-10 px-3 w-full">
              <div className="bg-white w-full mt-3 rounded-md px-2 py-4">
                <div className="text-[#003DA5] font-bold-mon ">
                  <span className="uppercase text-[18px]">
                    Chụp ảnh THÔNG TIN ĐƠN HÀNG
                  </span>
                  {imageFile ? (
                    <div className="relative">
                      <label
                        className="absolute top-0 right-0 z-20"
                        // onClick={handleClose}
                        htmlFor="upload"
                      >
                        <img src={CLOSE} className="w-6" />
                      </label>
                      <img src={imageFile} className="relative z-10" />
                      <button
                        className="w-full relative flex mt-2"
                        onClick={handleConfirm}
                      >
                        <img src={BUTTON_JOIN} className="w-full" />
                        <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-white font-bold text-[18px] w-full uppercase">
                          <div className="flex justify-center">
                            <div>
                              <img src={ICON_CAM} />
                            </div>
                            <div className="ml-2">Xác nhận</div>
                          </div>
                        </div>
                      </button>
                    </div>
                  ) : (
                    <label
                      htmlFor="upload"
                      className="w-full relative flex mt-2"
                    >
                      <img src={BUTTON_JOIN} className="w-full" />
                      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-white font-bold text-[18px] w-full uppercase">
                        <div className="flex justify-center">
                          <div>
                            <img src={UPLOAD} />
                          </div>
                          <div className="ml-2">Đăng tải hình</div>
                        </div>
                      </div>
                    </label>
                  )}

                  <input
                    onChange={(e) => handleChangeImage(e)}
                    className="uploadFile"
                    style={{ display: "none" }}
                    id="upload"
                    type="file"
                    accept="image/*"
                    //   ref={refInputUpload}
                  />
                  <div className="mt-2 uppercase">hướng dẫn chụp ảnh</div>
                  <div className="font-regular-mon text-[14px] text-black mt-2">
                    Vào mục “Đơn Mua” tải ảnh chụp màn hình “THÔNG TIN ĐƠN HÀNG”
                    khi mua và nhận hàng thành công để xác nhận thông tin.
                  </div>
                  <div>
                    <img src={CONTENT} />
                  </div>
                  <div className="bg-[#FFFDEE] py-2">
                    <div className="flex">
                      <div className="ml-2">
                        <img src={ICON_PHONE} />
                      </div>
                      <div className="px-2 text-black text-justify font-bold-mon text-[12px]">
                        Liên hệ Hotline để được hướng dẫn chi tiết Số hotline
                        (028) 36222399
                      </div>
                    </div>
                    <div className="flex justify-around">
                      {/* <button className="bg-[#BDBDBD] flex items-center px-5 text-black text-[13px] rounded-xl">
                        <span className="mr-2">
                          <img src={BACK} />
                        </span>
                        <span>Trở lại</span>
                      </button>
                      <button className="bg-[#003DA5] flex items-center px-2 py-1 text-white text-[13px] rounded-xl">
                        <span className="mr-1">
                          <img src={BTN_NEXT} />
                        </span>
                        <span>
                          Bỏ qua
                          <br />
                          hướng dẫn
                        </span>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="h-40">l</div> */}
            <img src={BG} className="relative z-0 " />
            <Footer />
          </div>
        </div>
      )}
    </Fragment>
  );
}
