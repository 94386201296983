import INTRO_TOP from "../assets/image/intro-shopee-top.png";
import INTRO_BOTTOM from "../assets/image/intro-shopee-bottom.png";
import BUTTON_JOIN from "../assets/image/button-join.png";
import BUTTON_TC from "../assets/image/button-tc.png";
import "./Intro.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { receiptServices } from "../services/apiServices";

export default function Intro() {
  const navigation = useNavigate();
  const appCode = window.location.pathname.split("/")[1];

  const handleCheckPhone = () => {
    navigation("/shopee/checkphone");
  };
  useEffect(() => {
    const data = {
      campaign_name: appCode,
    };
    receiptServices
      .getDetailCampaign(data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("CAMPAIGN", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="relative">
      <div className="">
        <img src={INTRO_TOP} className="relative top-0 w-full z-10" />
      </div>
      <div className="w-full relative -top-[1px] z-0">
        <img src={INTRO_BOTTOM} />
        {/* <button> 
        </button> */}
        <div className="absolute top-5 px-10">
          <button className="w-full relative flex" onClick={handleCheckPhone}>
            <img src={BUTTON_JOIN} className="w-full" />
            <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-white font-bold text-[20px] w-full uppercase">
              <div> Tham gia chương</div>
              <div className="-mt-2"> trình ngay</div>
            </div>
          </button>
          {/* <button className="w-full mt-2 relative">
            <img src={BUTTON_TC} className="w-full" />
            <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-[#003DA5] font-bold text-[20px] w-full uppercase">
              Thể lệ chương trình
            </div>
          </button> */}
        </div>
      </div>
    </div>
  );
}
